import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environment/environment';

@Injectable({ providedIn: 'root' })
export class HttpHelperService {
  constructor(private http: HttpClient) {}

  public SendPostRequest<R>(
    url: string,
    request?: any,
    customHeaders?: Headers,
    isStopSendToken?: boolean
  ) {
    return this.http.post<R>(environment.BackEndURL + '/' + url, request);
  }

  public SendPostRequestAndReturnView(
    url: string,
    request?: any,
    customHeaders?: Headers,
    isStopSendToken?: boolean
  ) {
    return this.http.post(environment.BackEndURL + '/' + url, request, {
      responseType: 'text',
    });
  }

  public SendGetRequest<R>(
    url: string,
    customHeaders?: Headers,
    isStopSendToken?: boolean
  ) {
    return this.http.get<R>(environment.BackEndURL + '/' + url);
  }
}
