<div class="row">
  <div class="col-12 col-lg-6">
    <app-payment-schedule
      [paymentPlan]="selectedPaymentOption?.paymentPlan"
      [cashBackEnable]="cashBackEnable"
      [paidAmountNowAfterWallet]="
        selectedPaymentOption?.paidAmountNowAfterWallet ?? 0
      "
      [isFreeLastInstalment]="
        selectedPaymentOption?.isFreeLastInstalment ?? false
      "
      [paymentOptionType]="selectedPaymentOption?.paymentOptionType"
    ></app-payment-schedule>
  </div>

  <div
    *ngIf="!fullCashBack || userNeedToAddCard"
    class="payment-options-widget col-12 col-lg-6"
  >
    <p class="section-title">{{ "payment-options" | translate }}</p>

    <div class="payment-ways">
      <div
        class="payment-way"
        [ngClass]="{
          selected: selectedPaymentType === paymentType.Card,
          'add-card': userNeedToAddCard
        }"
      >
        <label class="container-radio">
          <input
            type="radio"
            name="paymentOptionGroup"
            id="master-visa"
            (change)="onChangePaymentType(paymentType.Card)"
            [(ngModel)]="selectedPaymentType"
            [value]="paymentType.Card"
          />
          <span class="checkmark"></span>
        </label>

        <div class="payment-way-details">
          <img
            loading="lazy"
            src="../../assets/images/master-visa.svg"
            style="object-fit: cover"
            alt="master-visa"
          />
          <div class="payment-way-details-titles">
            <div class="title">
              {{ "Visa-MasterCard" | translate }}
            </div>
            @if(!userNeedToAddCard){
            <div class="subtitle">
              {{ "Pay-through-Visa" | translate }}
            </div>
            } @else {
            <div>
              <button
                type="button"
                class="text-button"
                (click)="handleAddCard('1')"
              >
                + {{ "add-card" | translate }}
              </button>
            </div>
            }
          </div>
        </div>
      </div>

      <div
        class="payment-way"
        [ngClass]="{
          selected: selectedPaymentType === paymentType.MADA,
          'add-card': userNeedToAddCard
        }"
      >
        <label class="container-radio">
          <input
            type="radio"
            name="paymentOptionGroup"
            id="master-visa"
            (change)="onChangePaymentType(paymentType.MADA)"
            [(ngModel)]="selectedPaymentType"
            [value]="paymentType.MADA"
          />
          <span class="checkmark"></span>
        </label>

        <div class="payment-way-details">
          <img
            loading="lazy"
            src="../../assets/images/mada-visa.png"
            style="object-fit: contain"
            alt="mada-visa"
          />
          <div class="payment-way-details-titles">
            <div class="title">
              {{ "mada-Visa-card" | translate }}
            </div>

            @if(!userNeedToAddCard){
            <div class="subtitle">
              {{ "Pay-through-Mada" | translate }}
            </div>
            } @else {
            <div>
              <button
                type="button"
                class="text-button"
                (click)="handleAddCard('2')"
              >
                + {{ "add-card" | translate }}
              </button>
            </div>
            }
          </div>
        </div>
      </div>

      <div
        *ngIf="isAllowApplePay"
        class="payment-way"
        [ngClass]="{
          selected: selectedPaymentType === paymentType.ApplePay,
          disabled: userNeedToAddCard
        }"
      >
        <label
          class="container-radio"
          [ngClass]="{ disabled: userNeedToAddCard }"
        >
          <input
            type="radio"
            name="paymentOptionGroup"
            id="master-visa"
            (change)="onChangePaymentType(paymentType.ApplePay)"
            [(ngModel)]="selectedPaymentType"
            [value]="paymentType.ApplePay"
            [disabled]="userNeedToAddCard"
          />
          <span class="checkmark"></span>
        </label>

        <div class="payment-way-details">
          <img
            loading="lazy"
            src="../../assets/images/apple-pay.svg"
            style="object-fit: cover"
            alt="apple-pay"
          />
          <div class="payment-way-details-titles">
            <div class="title">
              {{ "ApplePay" | translate }}
            </div>
            <div class="subtitle">
              {{ "Pay-through-ApplePay" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-promo-code
      [referralOffer]="referralOffer"
      [orderId]="orderId"
      [disabled]="false"
    />
  </div>

  <div class="terms-and-conditions col-12">
    <label class="check-box-container">
      <input
        type="checkbox"
        [(ngModel)]="termsValue"
        (change)="onTermsAndConditionsClick()"
      />
      <span class="checkmark"></span>

      {{ "confirm-read-terms-and-conditions" | translate }}
      <a
        class="terms-link"
        href="https://madfu.com.sa/terms-and-conditions"
        target="_blank"
      >
        {{ "terms-and-conditions" | translate }}
      </a>
    </label>
  </div>

  <div class="col-12 p-0">
    <button
      [disabled]="!termsValue || userNeedToAddCard"
      type="button"
      class="pay-btn btn btn-primary col-12"
      (click)="startPaymentOperation()"
    >
      {{ "pay" | translate }}
      {{ firstPayment }}
      <span class="rial">{{ "Riyal" | translate }}</span>
      {{ "now" | translate }}

      <img
        src="../../../assets/images/login/side-arrow.svg"
        class="img-fluid ms-2"
        alt="arrow"
      />
    </button>
  </div>
</div>

<app-add-card-iframe
  [paymentPageLink]="paymentPageLink"
  (cardAdded)="handleCardAdded()"
></app-add-card-iframe>
