import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-card-iframe',
  standalone: false,
  templateUrl: './add-card-iframe.component.html',
  styleUrl: './add-card-iframe.component.scss',
})
export class AddCardIframeComponent {
  @Input({ required: true }) paymentPageLink: string = '';

  @Output() cardAdded = new EventEmitter<void>();

  @ViewChild('addCardIFrame') addCardIFrame?: ElementRef;

  sanitizedThreeDSUrl: SafeUrl | undefined;
  isErrorModalOpened = false;

  constructor(
    private sanitizer: DomSanitizer,
    private readonly toaster: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['paymentPageLink'] && this.paymentPageLink) {
      this.openModal();
    }
  }

  openModal() {
    this.sanitizedThreeDSUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.paymentPageLink
    );

    document.getElementById('open-add-card-button')?.click();
  }

  closeModal() {
    document.getElementById('close-add-card-button')?.click();
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent): void {
    // Access the data sent from the iframe
    const success = event?.data?.success;

    if (success === true) {
      this.closeModal();
      this.cardAdded.emit();
    } else if (success === false) {
      this.closeModal();
      this.toaster.error('', event?.data?.message);
    }
  }
}
