import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { CheckoutService } from 'src/app/services/checkout.service';
import { PaymentService } from 'src/app/services/payment.service';
import {
  PaymentOptionStep,
  PaymentOptionType,
} from 'src/app/enums/PaymentType';
import { PaymentProcessService } from 'src/app/services/payment-process.service';
import { take } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ANALYTICS_EVENTS } from 'src/app/constants';
import { PagesLayoutService } from 'src/app/services/pages-layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-payment-options',
  standalone: false,
  templateUrl: './payment-options.component.html',
  styleUrl: './payment-options.component.scss',
})
export class PaymentOptionsComponent implements OnInit, OnDestroy {
  @ViewChild('paymentOptionsSelection') paymentOptionsSelection: any;

  isPaymentOptionsPage$ = this.pagesLayoutService.isPaymentOptionsPage$;

  step = PaymentOptionStep.OPTION_SELECT;
  PaymentOptionStep = PaymentOptionStep;

  orderDetailsRes: OrderDetailsRes | undefined;
  selectedPaymentOption: any;
  selectedPaymentOptionId: number = 0;
  noOfInstallments: any;
  cashBackEnable: boolean = false;
  paymentOptionData: any;
  paymentOptionTitle: string = '';

  paymentOptionRes: any;
  balance: string = '0';
  paymentOption: any;
  totalAmount: number = 0;
  orderId: number = 0;
  loading: boolean = false;
  payNowAmount: number = 0;
  paydayOption: any;
  selectedPaydayOption: any;
  PaymentOptionType = PaymentOptionType;
  selectedPaymentOptionType: number = 0;
  userNeedToAddCard: boolean = false;

  // payment Methods variables
  fullCashBack: boolean = false;
  referralOffer: { code: string; id: number } | undefined;

  destroyRef = inject(DestroyRef);

  constructor(
    private readonly paymentService: PaymentService,
    private readonly checkoutService: CheckoutService,
    private readonly paymentProcessService: PaymentProcessService,
    private readonly analyticsService: AnalyticsService,
    private readonly pagesLayoutService: PagesLayoutService
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.subscribeOrderDetails();
    this.pagesLayoutService.setIsPaymentOptionsPage();

    this.setOptionSelectStep();
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(take(1))
      .subscribe((orderDetailsRes: OrderDetailsRes) => {
        this.orderDetailsRes = orderDetailsRes;
        this.totalAmount = orderDetailsRes?.data.totalAmount;
        this.orderId = orderDetailsRes?.data.orderId ?? 0;

        this.getPaymentOptions();
      });
  }

  setOptionSelectStep() {
    this.isPaymentOptionsPage$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isPaymentOptionsPage) => {
        if (isPaymentOptionsPage) {
          this.step = PaymentOptionStep.OPTION_SELECT;
        }
      });
  }

  getPaymentOptions() {
    this.paymentService.PaymentOptionNewStructure(this.orderId).subscribe({
      next: (res: any) => {
        this.paymentOptionRes = res.data?.paymentOptionGroup;
        this.paymentOptionData = res.data;

        this.userNeedToAddCard = res.data.userNeedToAddCard;

        this.balance = res.data.userWalletBalance;
        localStorage.setItem('walletAmount', this.balance);

        this.referralOffer = res.data.referralOffer;

        if (this.paymentOptionRes?.length > 0) {
          this.setDefaultPaydayOption();

          setTimeout(() => {
            this.paymentOptionsSelection.toggleCollapse(
              this.paymentOptionRes[0].paymentOptionType
            );
          }, 200);
        }

        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
      },
    });
  }

  onChangeOptionId(e: any, paymentOptionTitle: string) {
    this.selectedPaymentOption = e;
    this.selectedPaymentOptionId = e.optionId;
    this.selectedPaymentOptionType = e.paymentOptionType;
    this.noOfInstallments = e.noOfInstallments;
    this.paymentOptionTitle = paymentOptionTitle;

    this.sendAnalyticsEventForOptionChange();

    this.updatePayNowAmount();

    this.fullCashBack = this.paymentProcessService.calculatePaidAmount(
      this.cashBackEnable,
      this.selectedPaymentOption
    );
  }

  private sendAnalyticsEventForOptionChange() {
    let analyticsEvent =
      ANALYTICS_EVENTS.PAYMENT_OPTIONS.SPLIT_PAYMENTS_INTO_MONTHS.replace(
        '${period}',
        this.noOfInstallments
      );
    if (this.selectedPaymentOptionType === PaymentOptionType.PayDay) {
      analyticsEvent =
        ANALYTICS_EVENTS.PAYMENT_OPTIONS.PAYDAY_PERCENTAGE.replace(
          '${percentage}',
          this.selectedPaymentOption.percentageDownPayment
        );
    }

    this.analyticsService.sendEvent(analyticsEvent);
  }

  private updatePayNowAmount() {
    if (this.cashBackEnable) {
      this.payNowAmount = this.selectedPaymentOption.paidAmountNowAfterWallet;
    } else {
      this.payNowAmount = this.selectedPaymentOption.paidAmountNow;
    }
  }

  setDefaultPaydayOption() {
    this.paydayOption = this.paymentOptionRes.find((option: any) => {
      return option.paymentOptionType == this.PaymentOptionType.PayDay;
    });
    if (this.paydayOption) {
      this.selectedPaydayOption = this.paydayOption.options[0];
    }
  }

  previousPayDayOption(item: any, paymentOptionTitle: string) {
    for (let i = 0; i < this.paydayOption.options.length; i++) {
      if (this.paydayOption.options[i] == item) {
        this.setPaydayOption(i - 1, paymentOptionTitle);
      }
    }
  }

  nextPayDayOption(item: any, paymentOptionTitle: string) {
    for (let i = 0; i < this.paydayOption.options.length; i++) {
      if (this.paydayOption.options[i] == item) {
        this.setPaydayOption(i + 1, paymentOptionTitle);
      }
    }
  }

  setDataAfterCollapseOpened(item: any) {
    this.setDefaultPaydayOption();
    const selectedOption = item.options[0];

    this.noOfInstallments = selectedOption.noOfInstallments;
    this.selectedPaymentOption = selectedOption;
    this.paymentOptionTitle = item.title;
    this.selectedPaymentOptionId = selectedOption.optionId;
    this.selectedPaymentOptionType = item.paymentOptionType;

    this.updatePayNowAmount();

    this.fullCashBack = this.paymentProcessService.calculatePaidAmount(
      this.cashBackEnable,
      this.selectedPaymentOption
    );
  }

  setPaydayOption(i: number, paymentOptionTitle: string) {
    if (i < this.paydayOption.options.length && i >= 0) {
      this.selectedPaydayOption = this.paydayOption.options[i];
      this.onChangeOptionId(this.paydayOption.options[i], paymentOptionTitle);
    }
  }

  checkCashBackValue(event: any) {
    this.cashBackEnable = event.target.checked;

    if (this.selectedPaymentOption) {
      if (this.cashBackEnable) {
        this.payNowAmount = this.selectedPaymentOption.paidAmountNowAfterWallet;
      } else {
        this.payNowAmount = this.selectedPaymentOption.paidAmountNow;
      }

      this.fullCashBack = this.paymentProcessService.calculatePaidAmount(
        this.cashBackEnable,
        this.selectedPaymentOption
      );
    }

    if (this.cashBackEnable) {
      this.analyticsService.sendEvent(
        ANALYTICS_EVENTS.PAYMENT_OPTIONS.CASHBACK_ENABLED
      );
    } else {
      this.analyticsService.sendEvent(
        ANALYTICS_EVENTS.PAYMENT_OPTIONS.CASHBACK_DISABLED
      );
    }
  }

  openPaymentMethods() {
    this.analyticsService.sendEvent(ANALYTICS_EVENTS.PAYMENT_OPTIONS.PAY_NOW);

    this.setPaymentData();

    this.step = PaymentOptionStep.METHOD_SELECT;
    this.pagesLayoutService.setIsPaymentMethodsPage();
  }

  setPaymentData() {
    this.paymentProcessService.savePaymentOptionsData(
      this.orderDetailsRes?.data?.orderId!,
      this.orderDetailsRes?.data?.guestOrderData?.customerName!,
      this.selectedPaymentOptionId,
      this.selectedPaymentOption,
      this.noOfInstallments,
      this.cashBackEnable,
      this.paymentOptionData.referralCode,
      this.paymentOptionTitle,
      this.selectedPaymentOption.paidAmountNow,
      this.paymentOptionData.referralOffer,
      this.paymentOptionData.isFreeLastInstalment
    );
  }

  handleCardAdded() {
    this.userNeedToAddCard = false;
  }

  ngOnDestroy() {
    this.pagesLayoutService.resetPages();
  }
}
