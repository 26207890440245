import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { interval, Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SignUpService } from 'src/app/pages/sign-up/services/sign-up.service';
import {
  OnboardingStep,
  SignUpData,
  SignUpOTPVerificationReq,
  SignUpOTPVerificationRes,
  SignUpSendOTPReq,
  SignUpSendOTPRes,
} from 'src/app/models/signUp';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PhoneVerificationFailureService } from 'src/app/pages/sign-up/services/phone-verification-failure.service';
import { ToastrService } from 'ngx-toastr';
import { NationalIdConfirmationService } from 'src/app/pages/sign-up/services/national-id-confirmation.service';
import { ANALYTICS_EVENTS } from 'src/app/constants';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-sign-up-otp-verification',
  standalone: false,
  templateUrl: './otp-verification.component.html',
  styleUrl: './otp-verification.component.scss',
})
export class OtpVerificationComponent implements OnInit, OnDestroy {
  @Output() openNextStep = new Subject<keyof typeof OnboardingStep>();

  otpForm: FormGroup;
  countdown: number = 120;

  enableSubmitBtn: boolean = true;
  showSubmitMsg: boolean = true;
  showVerificationError: boolean = false;
  isRequestPending = false;
  idGuid = '';
  errorMessage = '';

  nationalId$ = this.nationalIdConfirmationService.nationalId$;

  private readonly destroy$ = new Subject<void>();
  destroyRef = inject(DestroyRef);

  get minutes(): number {
    return Math.floor(this.countdown / 60);
  }

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly signUpService: SignUpService,
    private readonly phoneVerificationFailureService: PhoneVerificationFailureService,
    private readonly toaster: ToastrService,
    private readonly nationalIdConfirmationService: NationalIdConfirmationService,
    private readonly analyticsService: AnalyticsService
  ) {
    this.otpForm = this.fb.group({
      otp1: ['', [Validators.required, this.numericValidator()]],
      otp2: ['', [Validators.required, this.numericValidator()]],
      otp3: ['', [Validators.required, this.numericValidator()]],
      otp4: ['', [Validators.required, this.numericValidator()]],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.startTimer();
    this.subscribeUserData();
    this.checkIsChangeMobileClosed();
  }

  subscribeUserData() {
    this.signUpService.userData
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userData: SignUpData) => {
        this.idGuid = userData.idGuid;
      });
  }

  checkIsChangeMobileClosed() {
    this.phoneVerificationFailureService.isChangeMobileModalOpened$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isChangeMobileModalOpened) => {
        if (!isChangeMobileModalOpened) {
          this.destroy$.next();
          this.enableSubmitBtn = true;
          this.showSubmitMsg = true;
          this.showVerificationError = false;
          this.countdown = 120;
          this.startTimer();
        }
      });
  }

  openLoginPage() {
    this.analyticsService.sendEvent(ANALYTICS_EVENTS.SHARED.BACK);

    this.router.navigate(['/Payment'], {
      replaceUrl: true,
      queryParams: this.route.snapshot.queryParams,
    });
  }

  numericValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === null || value === undefined || value === '') {
        return null;
      }

      const sanitizedValue = value.replace(/\D/g, '');
      if (value !== sanitizedValue) {
        control.setValue(sanitizedValue);
      }

      return null;
    };
  }

  private startTimer() {
    interval(1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.destroy$.next();
        }
        this.cdr.markForCheck();
      });
  }

  moveFocus(
    value: any,
    nextInput: string,
    previousInput: string,
    currentInput: string
  ) {
    const inputValue = value.target.value;
    const inputNumber = Number(inputValue);

    if (value.key === 'Backspace') {
      this.otpForm.get(currentInput)?.patchValue(null);
      const element = document.getElementById(previousInput);
      if (element) {
        element.focus();
      }
    } else if (inputValue && inputNumber >= 0 && inputNumber <= 9) {
      const element = document.getElementById(nextInput);
      if (element) {
        element.focus();
      }
    }

    this.enableSubmitBtn = true;

    this.otpForm.setErrors(null);
  }

  handleEnterPress() {
    if (!this.isRequestPending && this.otpForm.valid) {
      this.handleCheckOTP();
    }
  }

  handleCheckOTP() {
    if (this.otpForm.valid && !this.isRequestPending) {
      this.isRequestPending = true;
      const userotp: string =
        this.otpForm.controls['otp1']?.value?.toString() +
        this.otpForm.controls['otp2']?.value?.toString() +
        this.otpForm.controls['otp3']?.value?.toString() +
        this.otpForm.controls['otp4']?.value?.toString();

      const signinReq: SignUpOTPVerificationReq = {
        plainOtp: userotp,
        otpId: this.idGuid,
      };

      this.phoneVerificationFailureService.changingMobileNumber$
        .pipe(take(1))
        .subscribe((isChangingMobile) => {
          if (isChangingMobile) {
            this.verifyChangedMobileOTP(signinReq);
          } else {
            this.verifyOtp(signinReq);
          }
        });
    }
  }

  verifyOtp(signinReq: SignUpOTPVerificationReq) {
    this.signUpService.VerifyOTP(signinReq).subscribe({
      next: (res: SignUpOTPVerificationRes) => {
        this.isRequestPending = false;
        this.otpForm.reset();

        localStorage.setItem('token', res.data.token);

        let onboardingStep = res.data.onboardingStep;
        let isVerificationPending = false;

        if (res.data.onboardingStep === 'verificationPending') {
          onboardingStep = 'addresspending';
          isVerificationPending = true;
        }

        this.signUpService.setVerificationPendingAfterOTP(
          isVerificationPending
        );
        this.signUpService.setOnboardingStep(OnboardingStep[onboardingStep]);
        this.handleOpenNextStep(onboardingStep);
      },
      error: (error) => {
        this.otpForm.setErrors({ incorrectOTP: true });

        this.enableSubmitBtn = false;
        this.showSubmitMsg = false;
        this.showVerificationError = true;
        this.isRequestPending = false;
        this.errorMessage = error.error.detail;
      },
    });
  }

  verifyChangedMobileOTP(signinReq: SignUpOTPVerificationReq) {
    this.signUpService.VerifyChangedMobileOTP(signinReq).subscribe({
      next: (res: SignUpOTPVerificationRes) => {
        this.isRequestPending = false;
        this.otpForm.reset();

        this.createNafathRequest();
      },
      error: (error) => {
        this.otpForm.setErrors({ incorrectOTP: true });

        this.enableSubmitBtn = false;
        this.showSubmitMsg = false;
        this.showVerificationError = true;
        this.isRequestPending = false;
        this.errorMessage = error.error.detail;
      },
    });
  }

  handleOpenNextStep(nextStep: keyof typeof OnboardingStep) {
    this.openNextStep.next(nextStep);
  }

  resendOTP() {
    this.otpForm.reset();

    const otpReq: SignUpSendOTPReq = {
      mobile: localStorage.getItem('mobile')!,
      mobileCountryCode: '+966',
    };

    this.signUpService.SendOTP(otpReq).subscribe({
      next: (res: SignUpSendOTPRes) => {
        if (res.succeeded) {
          this.signUpService.setIdGuid(res.data.idGuid);

          this.enableSubmitBtn = true;
          this.showSubmitMsg = true;
          this.showVerificationError = false;
          this.countdown = 120;
          this.startTimer();
        } else {
          this.showVerificationError = true;
        }

        this.isRequestPending = false;
        this.errorMessage = res.message!;
      },
      error: (error) => {
        this.showVerificationError = true;
        this.isRequestPending = false;
        this.errorMessage = error.error.detail;
      },
    });
  }

  createNafathRequest() {
    this.nationalId$.pipe(first()).subscribe((nationalId) => {
      this.signUpService.CreateNafathRequest(nationalId).subscribe({
        next: (res) => {
          this.isRequestPending = false;

          if (res.succeeded) {
            this.signUpService.SetTransIdAndRandomAndNationalId(
              res.data.transId,
              res.data.random,
              nationalId
            );
            this.handleOpenNextStep('addressverified');
          } else if (res.code === 1002) {
            this.handleOpenAddressPendingAfterNafathError(res.message!);
          }
        },
        error: (error) => {
          this.isRequestPending = false;
          this.handleOpenAddressPendingAfterNafathError(error.error?.detail);
        },
      });
    });
  }

  handleOpenAddressPendingAfterNafathError(message: string) {
    this.toaster.error('', message);
    this.signUpService.setVerificationPendingAfterOTP(true);
    this.handleOpenNextStep('addresspending');
  }

  protected handlePaste(event: ClipboardEvent): void {
    event.preventDefault();

    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text') || '';

    const splittedText = pastedText
      .split('')
      .map((char) => (!isNaN(Number(char)) ? char : ''));

    this.otpForm.setValue({
      otp1: splittedText[0],
      otp2: splittedText[1],
      otp3: splittedText[2],
      otp4: splittedText[3],
    });
  }
}
