import { Injectable } from '@angular/core';
import { environment } from 'src/app/environment/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private router: Router) {}

  public HandleSuccessPaymentResult(obj: any) {
    if (obj?.data?.merchantUrls?.success != null) {
      this.RedirectToLink(obj?.data?.merchantUrls?.success);
    } else {
      const params = this.ObjectToQueryParam(obj?.data);
      this.HandleLinkAfterTime(params, true);
    }
  }

  public HandleErrorPaymentResult(obj: any) {
    if (obj?.data?.merchantUrls?.failure != null) {
      this.RedirectToLink(obj?.data?.merchantUrls?.failure);
    } else {
      const params = this.ObjectToQueryParam(obj?.data);
      this.HandleLinkAfterTime(params, false);
    }
  }

  public HandleCancelPaymentResult(obj: any) {
    if (obj?.data?.merchantUrls?.cancel != null) {
      this.RedirectToLink(obj?.data?.merchantUrls?.cancel);
    } else {
      const params = this.ObjectToQueryParam(obj?.data);
      this.HandleLinkAfterTime(params, false);
    }
  }

  public HandleDirectCancelPaymentResult(obj: any) {
    if (obj?.data?.merchantUrls?.cancel != null) {
      this.DirectRedirectToLink(obj?.data?.merchantUrls?.cancel);
    } else {
      const params = this.ObjectToQueryParam(obj?.data);
      this.HandleLink(params, false);
    }
  }

  private ObjectToQueryParam(obj: any): { [k: string]: any } {
    let params: { [k: string]: any } = {};

    function appendParams(obj: any, parentKey?: string) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const adjustedKey = parentKey ? `${parentKey}.${key}` : key;

          if (typeof value === 'object' && value !== null) {
            // If the value is an object, recursively call appendParams
            continue;
          } else if (adjustedKey != 'merchantLogo') {
            // Otherwise, add the key-value pair to the params
            params[adjustedKey] = value;
          }
        }
      }
    }

    appendParams(obj);
    return params;
  }

  private RedirectToLink(link: string) {
    setTimeout(() => {
      window.location.href = link;
      localStorage.clear();
    }, environment.redirectPeriodInMilliSecond);
  }
  public DirectRedirectToLink(link: string) {
    window.location.href = link;
    localStorage.clear();
  }

  private HandleLinkAfterTime(params: Object, success: boolean) {
    setTimeout(() => {
      this.HandleLink(params, success);
    }, environment.redirectPeriodInMilliSecond);
  }

  private HandleLink(params: Object, success: boolean) {
    this.router.navigate(['/paymentResult'], {
      queryParams: {
        success: success,
        ...params,
      },
    });
    localStorage.clear();
  }
}
