import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SignInReq, SignInRes } from '../../models/siginIn';
import { AuthService } from '../../services/auth.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { interval, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ChangeLanguageService } from 'src/app/services/helper/change-language.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ANALYTICS_EVENTS } from 'src/app/constants';
import { TokenInitReq } from 'src/app/models/token-init';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-login-otp',
  standalone: false,
  templateUrl: './login-otp.component.html',
  styleUrl: './login-otp.component.scss',
})
export class LoginOtpComponent implements OnInit, OnDestroy {
  orderDetailsRes: OrderDetailsRes | undefined;
  mobileNumber: string | null = '';
  otpForm: FormGroup;
  countdown: number = 120;

  enableSubmitBtn: boolean = true;
  showSubmitMsg: boolean = true;
  showVerificationError: boolean = false;
  isRequestPending = false;
  haveActiveSession = false;

  private readonly destroy$ = new Subject<void>();
  destroyRef = inject(DestroyRef);

  get minutes(): number {
    return Math.floor(this.countdown / 60);
  }

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly checkoutService: CheckoutService,
    protected changeLanguageService: ChangeLanguageService,
    private readonly analyticsService: AnalyticsService
  ) {
    this.otpForm = this.fb.group({
      otp1: ['', [Validators.required, this.numericValidator()]],
      otp2: ['', [Validators.required, this.numericValidator()]],
      otp3: ['', [Validators.required, this.numericValidator()]],
      otp4: ['', [Validators.required, this.numericValidator()]],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.mobileNumber = localStorage.getItem('mobile');
    this.startTimer();
    this.subscribeOrderDetails();
    this.subscribeHaveActiveSession();
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(take(1))
      .subscribe((res: OrderDetailsRes) => {
        this.orderDetailsRes = res;
      });
  }

  subscribeHaveActiveSession() {
    this.authService.haveActiveSession$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((haveActiveSession: boolean) => {
        this.haveActiveSession = haveActiveSession;
      });
  }

  openLoginPage() {
    this.router.navigate(['/Payment'], {
      queryParams: { ...this.route.snapshot.queryParams },
    });
  }

  numericValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === null || value === undefined || value === '') {
        return null; // Let required validator handle empty values
      }

      const sanitizedValue = value.replace(/\D/g, '');
      if (value !== sanitizedValue) {
        control.setValue(sanitizedValue);
      }

      return null;
    };
  }

  private startTimer() {
    interval(1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.showVerificationError = false;
          this.destroy$.next();
        }
        this.cdr.markForCheck();
      });
  }

  moveFocus(
    value: any,
    nextInput: string,
    previousInput: string,
    currentInput: string
  ) {
    const inputValue = value.target.value;
    const inputNumber = Number(inputValue);

    if (value.key === 'Backspace') {
      this.otpForm.get(currentInput)?.patchValue(null);
      const element = document.getElementById(previousInput);
      if (element) {
        element.focus();
      }
    } else if (inputValue && inputNumber >= 0 && inputNumber <= 9) {
      const element = document.getElementById(nextInput);
      if (element) {
        element.focus();
      }
    }

    this.enableSubmitBtn = true;

    this.otpForm.setErrors(null);
  }

  handleEnterPress() {
    if (!this.isRequestPending && this.otpForm.valid) {
      this.checkOTP();
    }
  }

  checkOTP() {
    this.isRequestPending = true;
    const userotp: string =
      this.otpForm.controls['otp1']?.value?.toString() +
      this.otpForm.controls['otp2']?.value?.toString() +
      this.otpForm.controls['otp3']?.value?.toString() +
      this.otpForm.controls['otp4']?.value?.toString();

    const signinReq: SignInReq = {
      mobile: localStorage?.getItem('mobile') ?? '',
      PlainOtp: userotp,
      OtpId: localStorage?.getItem('otpId') ?? '',
      orderId: this.orderDetailsRes?.data.orderId,
    };

    this.authService.SignIn(signinReq).subscribe({
      next: (res: SignInRes) => {
        if (res.data.token == null && !res.succeeded) {
          this.otpForm.setErrors({ incorrectOTP: true });

          this.enableSubmitBtn = false;
          this.showSubmitMsg = false;
          this.showVerificationError = true;
          this.isRequestPending = false;

          this.getInitToken(() => {
            this.analyticsService.sendEvent(
              ANALYTICS_EVENTS.SIGN_IN_OTP.WRONG_OTP,
              { otp: userotp }
            );
          });

          return;
        }

        localStorage.setItem('token', res.data.token);

        this.analyticsService.sendEvent(
          ANALYTICS_EVENTS.SIGN_IN_OTP.CORRECT_OTP
        );

        this.openPaymentOptionsPage();
      },
      error: () => {
        this.otpForm.setErrors({ incorrectOTP: true });

        this.enableSubmitBtn = false;
        this.showSubmitMsg = false;
        this.showVerificationError = true;
        this.isRequestPending = false;

        this.getInitToken(() => {
          this.analyticsService.sendEvent(
            ANALYTICS_EVENTS.SIGN_IN_OTP.WRONG_OTP,
            { otp: userotp }
          );
        });
      },
    });
  }

  openPaymentOptionsPage() {
    this.router.navigate(['/payment-options'], {
      queryParams: { ...this.route.snapshot.queryParams },
    });
  }

  resentOTP() {
    this.getInitToken(() => {
      this.analyticsService.sendEvent(ANALYTICS_EVENTS.SIGN_IN_OTP.RESEND_OTP);
    });

    this.otpForm.reset();

    const signinReq: SignInReq = {
      mobile: localStorage?.getItem('mobile') ?? '',
      orderId: this.orderDetailsRes?.data.orderId,
    };

    this.authService.SignIn(signinReq).subscribe({
      next: (res: SignInRes) => {
        if (res.succeeded) {
          localStorage?.setItem('otpId', res.data.otpId!);

          this.enableSubmitBtn = true;
          this.showSubmitMsg = true;
          this.showVerificationError = false;
          this.countdown = 120;
          this.startTimer();
        } else {
          this.showVerificationError = true;
        }
      },
      error: () => {
        this.showVerificationError = true;
      },
    });
  }

  changeLanguage() {
    this.changeLanguageService.changeLanguage();
  }

  protected handlePaste(event: ClipboardEvent): void {
    event.preventDefault();

    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text') || '';

    const splittedText = pastedText
      .split('')
      .map((char) => (!isNaN(Number(char)) ? char : ''));

    this.otpForm.setValue({
      otp1: splittedText[0],
      otp2: splittedText[1],
      otp3: splittedText[2],
      otp4: splittedText[3],
    });
  }

  private getInitToken(callback: Function) {
    const tokenReq: TokenInitReq = {
      systemInfo: window.navigator.userAgent,
      uuid: uuidv4().replace(/-/g, ''),
    };

    this.authService.TokenInit(tokenReq).subscribe((res: any) => {
      localStorage.setItem('token', res.data.token);

      callback();
    });
  }
}
