import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentOptionType } from 'src/app/enums/PaymentType';
import { AnalyticsService } from 'src/app/services/analytics.service';
import * as bootstrap from 'bootstrap';
import { ANALYTICS_EVENTS } from 'src/app/constants';

@Component({
  selector: 'app-payment-options-selection',
  standalone: false,
  templateUrl: './options-selection.component.html',
  styleUrl: './options-selection.component.scss',
})
export class OptionsSelectionComponent {
  @Input({ required: true }) paymentOptionRes: any;
  @Input({ required: true }) loading: boolean = false;
  @Input({ required: true }) selectedPaymentOptionType: number = 0;
  @Input({ required: true }) selectedPaymentOption: any;
  @Input({ required: true }) selectedPaymentOptionId: number = 0;
  @Input({ required: true }) selectedPaydayOption: any;
  @Input({ required: true }) balance: string = '0';
  @Input({ required: true }) payNowAmount: number = 0;
  @Input({ required: true }) cashBackEnable: boolean = false;

  @Output() setDataAfterCollapseOpened: EventEmitter<any> =
    new EventEmitter<any>();

  @Output() onChangeOptionId: EventEmitter<{
    e: any;
    paymentOptionTitle: string;
  }> = new EventEmitter<{
    e: any;
    paymentOptionTitle: string;
  }>();
  @Output() previousPayDayOption: EventEmitter<{
    item: any;
    paymentOptionTitle: string;
  }> = new EventEmitter<{ item: any; paymentOptionTitle: string }>();
  @Output() nextPayDayOption: EventEmitter<{
    item: any;
    paymentOptionTitle: string;
  }> = new EventEmitter<{
    item: any;
    paymentOptionTitle: string;
  }>();
  @Output() checkCashBackValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPaymentMethods: EventEmitter<void> = new EventEmitter<void>();

  expandedPaymentOption = 0;
  PaymentOptionType = PaymentOptionType;

  get isCashBackEnabled() {
    return Number.parseFloat(this.balance) > 0;
  }

  constructor(private readonly analyticsService: AnalyticsService) {}

  toggleCollapse(paymentOptionType: number) {
    const elementId = `flush-collapse-${paymentOptionType}`;
    const element = document.getElementById(elementId);

    if (!element) return;

    const collapse = new bootstrap.Collapse(element);
    const isCurrentlyExpanded =
      paymentOptionType === this.expandedPaymentOption;

    if (isCurrentlyExpanded) {
      collapse.hide();
      this.expandedPaymentOption = 0;
    } else {
      this.expandedPaymentOption = paymentOptionType;
      collapse.show();
      this.sendAnalyticsEvent(paymentOptionType);
    }
  }

  private sendAnalyticsEvent(paymentOptionType: number) {
    if (paymentOptionType === PaymentOptionType.PayDay) {
      this.analyticsService.sendEvent(ANALYTICS_EVENTS.PAYMENT_OPTIONS.PAYDAY);
      this.analyticsService.sendEvent(
        ANALYTICS_EVENTS.PAYMENT_OPTIONS.PAYDAY_PERCENTAGE.replace(
          '${percentage}',
          this.selectedPaymentOption.percentageDownPayment
        )
      );
    } else if (paymentOptionType === PaymentOptionType.Installments) {
      this.analyticsService.sendEvent(
        ANALYTICS_EVENTS.PAYMENT_OPTIONS.SPLIT_PAYMENTS
      );
    }
  }

  handleOpenCollapse(item: any) {
    const paymentOptionType = item.paymentOptionType;

    if (
      paymentOptionType === PaymentOptionType.PayInFull ||
      paymentOptionType === PaymentOptionType.PayDay
    ) {
      this.setDataAfterCollapseOpened.emit(item);
    }

    this.toggleCollapse(paymentOptionType);
  }

  onChangeOptionIdHandler(e: any, paymentOptionTitle: string) {
    this.onChangeOptionId.emit({ e, paymentOptionTitle });
  }
  previousPayDayOptionHandler(item: any, paymentOptionTitle: string) {
    this.previousPayDayOption.emit({ item, paymentOptionTitle });
  }
  nextPayDayOptionHandler(item: any, paymentOptionTitle: string) {
    this.nextPayDayOption.emit({ item, paymentOptionTitle });
  }
  checkCashBackValueHandler(event: any) {
    this.checkCashBackValue.emit(event);
  }
  openPaymentMethodsHandler() {
    this.openPaymentMethods.emit();
  }
}
