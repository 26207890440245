<div class="login-otp-page">
  <img
    src="../../../assets/images/login/lg-intersect.svg"
    class="img-fluid lg-intersect-1 d-none d-lg-block"
    alt="intersect"
  />
  <img
    src="../../../assets/images/login/lg-intersect.svg"
    class="img-fluid lg-intersect-2 d-none d-lg-block"
    alt="intersect"
  />

  <img
    src="../../../assets/images/login/sm-intersect.svg"
    class="img-fluid sm-intersect-1 d-lg-none"
    alt="intersect"
  />
  <img
    src="../../../assets/images/login/sm-intersect.svg"
    class="img-fluid sm-intersect-2 d-lg-none"
    alt="intersect"
  />

  <div class="container">
    <app-cancel-order-modal
      [isLogin]="true"
      [orderDetailsRes]="orderDetailsRes"
    />

    <div class="row">
      <div class="col-12 col-lg-6 p-0 order-2 order-lg-1">
        <div class="row">
          <div class="col-12 col-lg-10 offset-xxl-1">
            <p class="flexible-payment">
              {{ "Enjoy flexible payment solutions with MADFU" | translate }}
              <span>{{ "MADFU" | translate }}</span>
              <img
                src="../../../assets/images/login/flexible-payment.svg"
                class="img-fluid ms-2"
                alt="flexible payment"
              />
            </p>

            <h1>
              @if(haveActiveSession){
              {{ "enter-verification-code-from-app" | translate }}
              } @else {
              {{ "Enter Verification Code" | translate }}
              }
            </h1>

            <p class="col-12 welcome-title">
              @if(!haveActiveSession){
              {{ "We have sent otp" | translate }}
              <span>
                <span class="phone-number"> {{ mobileNumber }}</span>
              </span>
              } @else {
              {{ "otp-sent-to-app" | translate }}
              <span class="phone-number">
                {{ "madfu-app" | translate }}
              </span>
              }

              <span
                class="change-number"
                (click)="openLoginPage()"
                (keydown.enter)="openLoginPage()"
                >{{ "change number" | translate }}</span
              >
            </p>

            <form [formGroup]="otpForm" (ngSubmit)="checkOTP()">
              <div class="form-row align-items-center">
                <div class="col-auto">
                  <div class="input-group otp-group">
                    <input
                      type="tel"
                      id="otp1"
                      maxlength="1"
                      placeholder=""
                      formControlName="otp1"
                      (keyup)="moveFocus($event, 'otp2', '', 'otp1')"
                      [ngClass]="{
                        'red-border': otpForm.hasError('incorrectOTP')
                      }"
                      (paste)="handlePaste($event)"
                    />

                    <input
                      type="tel"
                      id="otp2"
                      maxlength="1"
                      placeholder=""
                      formControlName="otp2"
                      (keyup)="moveFocus($event, 'otp3', 'otp1', 'otp2')"
                      [ngClass]="{
                        'red-border': otpForm.hasError('incorrectOTP')
                      }"
                      (paste)="handlePaste($event)"
                    />

                    <input
                      type="tel"
                      id="otp3"
                      maxlength="1"
                      placeholder=""
                      formControlName="otp3"
                      (keyup)="moveFocus($event, 'otp4', 'otp2', 'otp3')"
                      [ngClass]="{
                        'red-border': otpForm.hasError('incorrectOTP')
                      }"
                      (paste)="handlePaste($event)"
                    />

                    <input
                      type="tel"
                      id="otp4"
                      maxlength="1"
                      placeholder=""
                      formControlName="otp4"
                      (keyup)="moveFocus($event, '', 'otp3', 'otp4')"
                      [ngClass]="{
                        'red-border': otpForm.hasError('incorrectOTP')
                      }"
                      (paste)="handlePaste($event)"
                    />
                  </div>
                </div>

                <div class="resend-area text-center">
                  <span *ngIf="showSubmitMsg" class="mt-3 expire-messgae">
                    {{ "Did-not-receive-the-code" | translate }}
                    <span>
                      <button
                        class="resend-button"
                        (click)="this.resentOTP()"
                        [disabled]="showVerificationError || countdown > 0"
                        type="button"
                      >
                        {{ "Resend-the-code" | translate }}
                      </button>
                    </span>
                    {{ "in" | translate }}
                    <div class="countdown-timer">
                      {{ minutes | number : "2.0-0" }} :
                      {{ countdown % 60 | number : "2.0" }}
                    </div>
                  </span>

                  <span
                    *ngIf="showVerificationError"
                    class="mt-3 verification-error"
                  >
                    {{
                      "The-verification-code-is-incorrect-try-again" | translate
                    }}

                    <span>
                      <button
                        class="resend-button"
                        type="button"
                        (click)="this.resentOTP()"
                      >
                        {{ "Resend-the-code" | translate }}
                      </button>
                    </span>
                  </span>
                </div>

                <div class="col-auto">
                  <button
                    type="submit"
                    [disabled]="!enableSubmitBtn || this.otpForm.invalid"
                    class="btn btn-primary col-12 submit-btn"
                  >
                    {{ "Submit" | translate }}

                    <img
                      src="../../../assets/images/login/side-arrow.svg"
                      class="img-fluid ms-2"
                      alt="arrow"
                    />
                  </button>
                </div>
              </div>
            </form>

            <div
              class="sharia-compliant d-flex align-items-center justify-content-center"
            >
              <p class="title d-flex align-items-center justify-content-center">
                <img
                  src="../../../assets/images/login/sharia-compliant.svg"
                  class="img-fluid me-1"
                  alt="Sharia Compliant"
                />
                {{ "Sharia-compliant" | translate }}
              </p>

              <p class="subtitle">
                {{ "Sharia-compliant-subtext" | translate }}
              </p>
            </div>

            <div class="col-auto">
              <button
                type="button"
                class="btn btn-text col-12 change-language-btn"
                (click)="changeLanguage()"
              >
                {{ "English" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-lg-5 order-1 order-lg-2 offset-lg-1 p-0 d-flex justify-content-end"
      >
        <img
          src="../../../assets/images/otp/madfu-otp-page.svg"
          class="img-fluid madfu-img"
          alt="Madfu"
        />
      </div>
    </div>
  </div>
</div>
