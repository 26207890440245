<div class="row">
  <div class="col-12">
    <div class="cart-amount-container mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div
          class="your-cart d-flex justify-content-between align-items-center"
        >
          <img
            src="../../../assets/images/payment-options/shopping-bag.svg"
            alt="cart"
            class="cart-icon"
          />

          <p class="ms-2">
            {{ "your-cart" | translate }}
          </p>
        </div>

        <div>
          <p class="amount">
            {{ totalAmount }}
            <span>{{ "Riyal" | translate }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
