import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PagesLayoutService {
  private isPaymentOptionsPage = new BehaviorSubject<boolean>(false);
  private isPaymentMethodsPage = new BehaviorSubject<boolean>(false);
  private isOrderSuccessPage = new BehaviorSubject<boolean>(false);

  isPaymentOptionsPage$ = this.isPaymentOptionsPage.asObservable();
  isPaymentMethodsPage$ = this.isPaymentMethodsPage.asObservable();
  isOrderSuccessPage$ = this.isOrderSuccessPage.asObservable();

  constructor() {}

  setIsPaymentOptionsPage() {
    this.isPaymentOptionsPage.next(true);
    this.isPaymentMethodsPage.next(false);
    this.isOrderSuccessPage.next(false);
  }

  setIsPaymentMethodsPage() {
    this.isPaymentMethodsPage.next(true);
    this.isPaymentOptionsPage.next(false);
    this.isOrderSuccessPage.next(false);
  }

  setIsOrderSuccessPage() {
    this.isOrderSuccessPage.next(true);
    this.isPaymentOptionsPage.next(false);
    this.isPaymentMethodsPage.next(false);
  }

  resetPages() {
    this.isPaymentOptionsPage.next(false);
    this.isPaymentMethodsPage.next(false);
    this.isOrderSuccessPage.next(false);
  }
}
