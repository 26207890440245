import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { CheckoutService } from 'src/app/services/checkout.service';
import { ChangeLanguageService } from 'src/app/services/helper/change-language.service';
import { ApplicationLanguages } from 'src/app/enums/lang';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ANALYTICS_EVENTS } from 'src/app/constants';
import { PagesLayoutService } from 'src/app/services/pages-layout.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-pages-layout',
  standalone: false,
  templateUrl: './pages-layout.component.html',
  styleUrl: './pages-layout.component.scss',
})
export class PagesLayoutComponent {
  orderDetailsRes: OrderDetailsRes | undefined;

  destroyRef = inject(DestroyRef);

  applicationLanguages = ApplicationLanguages;

  isPaymentOptionsPage$ = this.pagesLayoutService.isPaymentOptionsPage$;
  isPaymentMethodsPage$ = this.pagesLayoutService.isPaymentMethodsPage$;
  isOrderSuccessPage$ = this.pagesLayoutService.isOrderSuccessPage$;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private checkoutService: CheckoutService,
    protected changeLanguageService: ChangeLanguageService,
    private readonly analyticsService: AnalyticsService,
    protected readonly pagesLayoutService: PagesLayoutService
  ) {}

  ngOnInit(): void {
    this.subscribeOrderDetails();
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: OrderDetailsRes) => {
        this.orderDetailsRes = res;
      });
  }

  /*
   * changeLanguage Function
   *
   * Change the language of the application done in the payment options page
   * We use the refresh because there is some data in payment options retrieved
   * in only Arabic or English and we need the other language
   * so we refresh to call the API with the new language
   */
  changeLanguage() {
    const newLanguage = this.changeLanguageService.getNewLanguage();
    localStorage.setItem('lang', newLanguage);

    this.refresh();
  }

  refresh() {
    window.location.reload();
  }

  goBack() {
    this.isPaymentMethodsPage$
      .pipe(take(1))
      .subscribe((isPaymentMethodsPage) => {
        if (isPaymentMethodsPage) {
          this.analyticsService.sendEvent(ANALYTICS_EVENTS.SHARED.BACK);

          this.openPaymentOptionsPage();
        }
      });
  }

  openPaymentOptionsPage() {
    this.pagesLayoutService.setIsPaymentOptionsPage();
  }
}
