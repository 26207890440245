<button
  id="open-add-card-button"
  class="btn btn-text add-card-button"
  type="button"
  data-bs-toggle="modal"
  data-bs-target="#addCardModal"
></button>

<button
  id="close-add-card-button"
  class="btn btn-text add-card-button"
  type="button"
  data-bs-target="#addCardModal"
  data-bs-dismiss="modal"
  aria-label="Close"
></button>

<div
  class="modal fade add-card-modal"
  id="addCardModal"
  tabindex="-1"
  aria-labelledby="addCardModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <p class="title">{{ "card-info-confirmation" | translate }}</p>
        <p class="subtitle">{{ "future-payments-valid-card" | translate }}</p>

        <iframe
          title="add visa card"
          id="addCardIFrame"
          [src]="sanitizedThreeDSUrl"
          name="addCardIFrame"
          #addCardIFrame
        ></iframe>
      </div>
    </div>
  </div>
</div>
