import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { ANALYTICS_EVENTS } from 'src/app/constants';
import { PaymentData } from 'src/app/enums/PaymentType';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { HelperService } from 'src/app/services/Helper.service';
import { PagesLayoutService } from 'src/app/services/pages-layout.service';
import { PaymentProcessService } from 'src/app/services/payment-process.service';

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss'],
})
export class SuccessPaymentComponent implements OnInit {
  orderDetailsRes: OrderDetailsRes | undefined;
  customerName: string = '';
  paidAmountNow: number = 0;
  cashbackAmount: number = 0;
  paymentOptionTitle: string = '';
  orderReferenceNumber: string = '';

  constructor(
    private helperService: HelperService,
    private checkoutService: CheckoutService,
    private paymentProcessService: PaymentProcessService,
    private readonly analyticsService: AnalyticsService,
    private readonly pagesLayoutService: PagesLayoutService
  ) {}

  ngOnInit(): void {
    this.pagesLayoutService.setIsOrderSuccessPage();

    this.subscribeOrderDetails();
    this.subscribePaymentData();

    if (window.parent) {
      window.parent.postMessage(
        { callback: 'completeCallback', data: null },
        '*'
      );
    }

    this.helperService.HandleSuccessPaymentResult(this.orderDetailsRes);
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(take(1))
      .subscribe((orderDetailsRes: OrderDetailsRes) => {
        this.orderDetailsRes = orderDetailsRes;
        this.customerName =
          this.orderDetailsRes?.data?.guestOrderData?.customerName || '';
      });
  }

  subscribePaymentData() {
    this.paymentProcessService.paymentData
      .pipe(take(1))
      .subscribe((paymentData: PaymentData) => {
        this.paidAmountNow = paymentData.selectedPaymentOption?.paidAmountNow!;
        this.cashbackAmount = paymentData.cashbackFromOrder;
        this.paymentOptionTitle = paymentData.paymentOptionTitle;
        this.orderReferenceNumber = paymentData.orderReferenceNumber;
      });
  }

  public RedirectToMerchantSuccessLink() {
    this.analyticsService.sendEvent(ANALYTICS_EVENTS.PAYMENT_SUCCESS.GO_HOME);

    const successLink = this.orderDetailsRes?.data?.merchantUrls?.success;
    if (successLink) {
      this.helperService.DirectRedirectToLink(successLink);
    }
  }
}
