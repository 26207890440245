<div class="payment-options">
  <app-shopping-cart-bar [totalAmount]="totalAmount"></app-shopping-cart-bar>

  @if(step === PaymentOptionStep.OPTION_SELECT) {
  <app-payment-options-selection
    #paymentOptionsSelection
    [paymentOptionRes]="paymentOptionRes"
    [loading]="loading"
    [selectedPaymentOptionType]="selectedPaymentOptionType"
    [selectedPaymentOption]="selectedPaymentOption"
    [selectedPaymentOptionId]="selectedPaymentOptionId"
    [selectedPaydayOption]="selectedPaydayOption"
    [balance]="balance"
    [payNowAmount]="payNowAmount"
    [cashBackEnable]="cashBackEnable"
    (setDataAfterCollapseOpened)="setDataAfterCollapseOpened($event)"
    (onChangeOptionId)="onChangeOptionId($event.e, $event.paymentOptionTitle)"
    (previousPayDayOption)="
      previousPayDayOption($event.item, $event.paymentOptionTitle)
    "
    (nextPayDayOption)="
      nextPayDayOption($event.item, $event.paymentOptionTitle)
    "
    (checkCashBackValue)="checkCashBackValue($event)"
    (openPaymentMethods)="openPaymentMethods()"
  ></app-payment-options-selection>

  <app-unavailable-payment-options-widget
    *ngIf="!paymentOptionRes?.length && !loading"
  ></app-unavailable-payment-options-widget>
  } @else {
  <app-payment-methods-step
    [selectedPaymentOption]="selectedPaymentOption"
    [referralOffer]="referralOffer"
    [orderId]="orderId"
    [cashBackEnable]="cashBackEnable"
    [fullCashBack]="fullCashBack"
    [userNeedToAddCard]="userNeedToAddCard"
    (cardAdded)="handleCardAdded()"
  ></app-payment-methods-step>
  }
</div>
