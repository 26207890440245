import { Injectable } from '@angular/core';
import { HttpHelperService } from './base/http-helper.services';
import { PaymentOptionRes } from '../models/paymentOption';
import { PaymentType } from '../enums/PaymentType';
import { AmazonPaymentPage } from '../models/amazonPaymentPage';
import { ApplePayStartSessionRes } from '../models/ApplePayStartSessionRes';
import {
  ExecuteTransactionAfterApplePayReq,
  ExecuteTransactionAfterApplePayRes,
} from '../models/ExecuteTransactionAfterApplePay';
import {
  TransactionExecutionReq,
  TransactionExecutionRes,
} from '../models/TransactionExecution';
import { SurveyQuestion, SurveyQuestionAnswer } from '../models/surveyQuestion';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(private httpHelperService: HttpHelperService) {}
  public PaymentOptions(orderId: number) {
    const query = 'orderId=' + orderId;
    return this.httpHelperService.SendGetRequest<PaymentOptionRes>(
      'user/PaymentOption?' + query
    );
  }

  public PaymentOptionNewStructure(orderId: number) {
    const query = 'orderId=' + orderId;
    return this.httpHelperService.SendGetRequest<PaymentOptionRes>(
      'api/user/PaymentOption?' + query
    );
  }

  public PaymentTransaction(
    tendenser_id: number,
    order_id: number,
    otp: string,
    idGuid: string,
    cashBackBack: number = 0,
    paymentType: PaymentType = PaymentType.Card,
    offerId: string = ''
  ) {
    const transaction: TransactionExecutionReq = {
      OrderId: order_id,
      RewardWalletAmount: cashBackBack,
      PaymentOptionId: tendenser_id,
      PaymentType: paymentType,
      PaymentChanelId: 1,
      otp,
      otpId: idGuid,
      offerId: offerId || 0,
    };
    return this.httpHelperService.SendPostRequest<TransactionExecutionRes>(
      'api/users/Transaction',
      transaction
    );
  }

  public PaymentTransactionFullCashBack<R>(
    tendenser_id: number,
    order_id: number,
    otp: string,
    idGuid: string,
    cashBackBack: number = 0,
    offerId: string = ''
  ) {
    const transaction: TransactionExecutionReq = {
      OrderId: order_id,
      RewardWalletAmount: cashBackBack,
      PaymentOptionId: tendenser_id,
      PaymentType: 1,
      PaymentChanelId: 1,
      otp,
      otpId: idGuid,
      offerId: offerId || 0,
    };
    return this.httpHelperService.SendPostRequest<R>(
      'api/users/Transaction',
      transaction
    );
  }

  public PaymentTransactionApplePay(
    tendenser_id: number,
    order_id: number,
    otp: string,
    idGuid: string,
    cashBackBack: number = 0,
    offerId: string = ''
  ) {
    const transaction: TransactionExecutionReq = {
      OrderId: order_id,
      RewardWalletAmount: cashBackBack,
      PaymentOptionId: tendenser_id,
      PaymentType: PaymentType.ApplePay,
      PaymentChanelId: 1,
      otp,
      otpId: idGuid,
      offerId: offerId || 0,
    };

    return this.httpHelperService.SendPostRequest<AmazonPaymentPage>(
      'api/users/Transaction',
      transaction
    );
  }

  public ApplePayPaymentSession() {
    return this.httpHelperService.SendGetRequest<ApplePayStartSessionRes>(
      'api/user/payment/ApplePayPaymentSession'
    );
  }

  public ExecuteTransactionAfterApplePay(
    req: ExecuteTransactionAfterApplePayReq
  ) {
    return this.httpHelperService.SendPostRequest<ExecuteTransactionAfterApplePayRes>(
      'api/user/payment/ExecuteTransactionAfterApplePay',
      req
    );
  }

  public checkPromoCode(promoCode: string, orderId: number) {
    return this.httpHelperService.SendGetRequest<any>(
      'api/user/Reward/CheckOfferCodeValidity?code=' +
        promoCode +
        '&orderId=' +
        orderId
    );
  }

  public getSurveyQuestions() {
    return this.httpHelperService.SendGetRequest<{
      data: Array<SurveyQuestion>;
    }>('api/user/UserSurvey/GetSurveyDetails?surveyType=2');
  }

  public submitSurveyAnswers(
    surveyAnswers: Array<SurveyQuestionAnswer>,
    surveyId: number,
    orderId: number | undefined
  ) {
    return this.httpHelperService.SendPostRequest<any>(
      'api/user/UserSurvey/AnswerSurvey',
      { surveyAnswers, surveyId, orderId, surveyType: 2 }
    );
  }

  public InitiateAddCard(PaymentType: string) {
    return this.httpHelperService.SendPostRequest(
      'api/user/savecard/transaction',
      { PaymentType }
    );
  }
}
