import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shopping-cart-bar',
  standalone: false,
  templateUrl: './shopping-cart-bar.component.html',
  styleUrl: './shopping-cart-bar.component.scss',
})
export class ShoppingCartBarComponent {
  @Input({ required: true }) totalAmount: number = 0;
}
