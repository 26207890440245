import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { EmptyPageComponent } from './pages/empty-page/empty-page.component';
import { LoginOtpComponent } from './pages/login-otp/login-otp.component';
import { PagesLayoutComponent } from './layout/pages-layout/pages-layout.component';
import { PaymentOptionsComponent } from './pages/payment-options/payment-options.component';
import { SuccessPaymentComponent } from './pages/success-payment/success-payment.component';
import { ConfirmPaymentUsingBirthDateComponent } from './pages/confirm-payment-using-birth-date/confirm-payment-using-birth-date.component';
import { OtpTransactionConfirmationComponent } from './pages/otp-transaction-confirmation/otp-transaction-confirmation.component';
import { ThreeDsPaymentComponent } from './pages/three-ds-payment/three-ds-payment.component';
import { ApplePayComponent } from './pages/apple-pay/apple-pay.component';
import { ConfirmPaymentVaTelComponent } from './pages/confirm-payment-va-tel/confirm-payment-va-tel.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';

const routes: Routes = [
  {
    path: 'Payment',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'login-otp-verification',
    component: LoginOtpComponent,
    pathMatch: 'full',
  },
  {
    path: 'paymentResult',
    component: EmptyPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: PagesLayoutComponent,
    children: [
      {
        path: 'payment-options',
        component: PaymentOptionsComponent,
        pathMatch: 'full',
      },
      {
        path: 'payment-success',
        component: SuccessPaymentComponent,
        pathMatch: 'full',
      },
      {
        path: 'birth-date-verification',
        component: ConfirmPaymentUsingBirthDateComponent,
        pathMatch: 'full',
      },
      {
        path: 'otp-verification',
        component: OtpTransactionConfirmationComponent,
        pathMatch: 'full',
      },
      {
        path: 'card-payment',
        component: ThreeDsPaymentComponent,
        pathMatch: 'full',
      },
      {
        path: 'apply-pay-payment',
        component: ApplePayComponent,
        pathMatch: 'full',
      },
      {
        path: 'confirmation-call',
        component: ConfirmPaymentVaTelComponent,
      },
    ],
  },
  { path: '**', redirectTo: 'Payment' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
