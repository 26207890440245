import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ANALYTICS_EVENTS } from 'src/app/constants';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { SurveyQuestion } from 'src/app/models/surveyQuestion';
import { TokenInitReq } from 'src/app/models/token-init';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/Helper.service';
import { PaymentService } from 'src/app/services/payment.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-cancel-order-modal',
  standalone: false,
  templateUrl: './cancel-order-modal.component.html',
  styleUrl: './cancel-order-modal.component.scss',
})
export class CancelOrderModalComponent {
  @Input() isLogin: boolean = false;
  @Input({ required: true }) orderDetailsRes?: OrderDetailsRes;

  @ViewChild('cancelOrderModal') cancelOrderModal?: ElementRef;

  isOpened = false;
  step = 1;
  feedbackReasons: Array<SurveyQuestion> = [];
  textFeedbackReasons: Array<SurveyQuestion> = [];
  reasonsForm?: FormGroup;
  selectedReasons: number[] = [];
  loading = false;
  textQuestionType = 1;

  constructor(
    private helperService: HelperService,
    private readonly analyticsService: AnalyticsService,
    private paymentService: PaymentService,
    private readonly authService: AuthService
  ) {}

  openModal() {
    this.getInitToken(() => {
      this.analyticsService.sendEvent(ANALYTICS_EVENTS.SHARED.CANCEL_ORDER);
    });
  }

  openStep2(step: number) {
    this.paymentService.getSurveyQuestions().subscribe((response) => {
      this.feedbackReasons = response?.data?.filter(
        (item) => item.surveyQuestionType !== this.textQuestionType
      );
      this.textFeedbackReasons = response?.data?.filter(
        (item) => item.surveyQuestionType === this.textQuestionType
      );

      if (this.textFeedbackReasons.length) {
        const form = new FormGroup({});

        this.textFeedbackReasons.forEach((question) => {
          form.addControl(
            question.surveyQuestionId.toString(),
            new FormControl('')
          );
        });

        this.reasonsForm = form;
      }

      this.step = step;
    });
  }

  onChangeReason(reasonId: number) {
    if (!this.selectedReasons.includes(reasonId)) {
      this.selectedReasons.push(reasonId);
    } else {
      this.selectedReasons = this.selectedReasons.filter(
        (id) => id !== reasonId
      );
    }
  }

  submitReasonsForm() {
    const surveyAnswers = this.createSurveyAnswers();
    this.loading = true;

    this.paymentService
      .submitSurveyAnswers(
        surveyAnswers,
        this.feedbackReasons[0].surveyId,
        this.orderDetailsRes?.data?.orderId
      )
      .subscribe(() => {
        this.moveToStep3();
      });
  }

  private createSurveyAnswers() {
    const surveyAnswers = this.feedbackReasons.map((question) => ({
      questionId: question.surveyQuestionId,
      answer: this.selectedReasons.includes(question.surveyQuestionId)
        ? 'true'
        : 'false',
    }));

    const textSurveyAnswers = this.textFeedbackReasons.map((question) => ({
      questionId: question.surveyQuestionId,
      answer: this.reasonsForm?.get(question.surveyQuestionId.toString())
        ?.value,
    }));

    return [...surveyAnswers, ...textSurveyAnswers];
  }

  moveToStep3() {
    this.step = 3;
    this.loading = false;

    this.cancelOrder();
  }

  cancelOrder() {
    this.initiateCancelOrder();
    this.helperService.HandleCancelPaymentResult(this.orderDetailsRes);
  }

  directCancelOrder() {
    this.initiateCancelOrder();
    this.helperService.HandleDirectCancelPaymentResult(this.orderDetailsRes);
  }

  private initiateCancelOrder() {
    this.getInitToken(() => {
      this.analyticsService.sendEvent(
        ANALYTICS_EVENTS.SHARED.CANCEL_ORDER_CONFIRMATION
      );
    });

    if (window.parent) {
      window.parent.postMessage(
        { callback: 'cancelCallback', data: null },
        '*'
      );
    }
  }

  declineCancelOrder() {
    this.getInitToken(() => {
      this.analyticsService.sendEvent(
        ANALYTICS_EVENTS.SHARED.CANCEL_ORDER_DECLINE
      );
    });
  }

  private getInitToken(callback: Function) {
    const token = localStorage.getItem('token');

    if (token) {
      callback();

      return;
    }

    const tokenReq: TokenInitReq = {
      systemInfo: window.navigator.userAgent,
      uuid: uuidv4().replace(/-/g, ''),
    };

    this.authService.TokenInit(tokenReq).subscribe((res: any) => {
      localStorage.setItem('token', res.data.token);

      callback();
    });
  }
}
