<div class="payments-options-unavailable-widget">
  <p class="choice-of-payment">
    {{ "choice-of-payment" | translate }}
  </p>

  <div class="payments-options-unavailable">
    <img
      src="../../../assets/images/payment-options/payment-options-unavailable.svg"
      alt="payment options unavailable"
      class="img-fluid"
    />
    <h4>{{ "payments-options-unavailable" | translate }}</h4>
    <p>{{ "payments-options-unavailable-description" | translate }}</p>
  </div>
</div>
